.groupCard {
    margin: 15px auto;
    width: 300px;
    height: 125px;
    border-radius: 20px;
    box-shadow: 2px 2px 15px 3px rgba(0,0,0,0.25), -2px -2px 15px 3px rgba(0,0,0,0.22);
    cursor: pointer;
    transition: 0.4s;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    background: #ef5350;
}

.titleRow {
    display: flex;
    justify-content: space-around;
    flex-grow: 1;
    align-items: center;
    padding: 0 5px 0 5px;
}

.descRow {
    flex-grow: 2;
    width: inherit;
    height: inherit;
    text-align: justify;
    word-wrap    : normal;
    overflow-wrap: break-word;
    position: relative;
    padding: 8px;
    text-justify: inter-word;
}

.group_name {
    text-align: center;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 24px;
    flex: 2;
}

.nameCount {
    text-align: center;
    font-size: 30px;
    flex: 1;
}

.groupCard:hover, .groupCard:active {
    transform: scale(0.9, 0.9);
    box-shadow: 3px 3px 20px 7px rgba(0,0,0,0.25), 
        -3px -3px 20px 7px rgba(0,0,0,0.22);
}
.dot {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    border:2px solid white;
}