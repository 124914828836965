.profileCard {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    height: auto;
    width:300px;
    box-shadow: 2px 2px 15px 3px rgba(0,0,0,0.25), -2px -2px 15px 3px rgba(0,0,0,0.22);
    transition: 0.3s linear;
    background-color: black;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: black
}
.icon{
    height: 125px;
}
.profileTopRow {
    width: inherit;
    height: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}
.profileName {
    height: auto;
    width: 150px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: #fff;
    padding: 0 0.1em 0 .1em;
    /* border: 2px solid #fff; */
    position: relative;
    pointer-events: none;
    align-items: center;
    font-size: 2em;	
    padding: 5px;
    word-wrap    : normal;
    overflow-wrap: break-word;
}

.firstName {
    text-align: center;
    width: inherit;
    height: inherit;

}
.lastName {    
    width: inherit;
    height: inherit;
}

.profileDescription {
    width: inherit;
    height: inherit;
    text-align: center;
    position: relative;
    align-items: left;
    flex-grow: 2;
    font-size: 25px;
    word-wrap    : normal;
    overflow-wrap: break-word;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.userEmail {
    font-size: 25px;
    color: white;
}

.profileBottomRow {
    width: inherit;
    height: inherit;
    background: green;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-grow: 1;
}