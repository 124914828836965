@font-face {
    font-family: 'Roboto';
    font-weight: 400;
    src: local('Roboto'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 300;
    src: local('Roboto'), url(./fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(./fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 700;
    src: local('Roboto'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}
html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
    font-family: 'Roboto', sans-serif; 
}
body.modal-open {
    padding-right: 0 !important;
}
.group-list, .contact-list {
    z-index: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
}
@media all and (max-width: 500px) {
    .group-list, .contact-list{
        flex-direction: column;
    }
}

.title-white {
    color: white;
}

.title-black {
    color: black;
}

.spinner { 
    position: fixed; 
    top: 50%;
    left: 50%;
    width: 4rem;
    height: 4rem;
}
.nameContainer, .groupContainer {
    margin-bottom: 65px;
}
.emptyTitle{
    margin-top: 10px;
    font-size: 50px;
    font-weight: 500;
    display:flex;
    justify-content: space-evenly;
}
.logoTitle{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 70px;
    font-weight: 500;
    display:flex;
    justify-content: space-evenly;
}
.emptyMessage{
    padding: 20px;
    font-size: 25px;
}
.emptyContainer{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: 280px;
    text-align: center;
}
.emptyBackground{
    background: url('./icons/group_zero.jpg') center;
    height:100%;
    width: 100%;
    object-fit: contain;
}